'use client';

import Auth0Helper from '@mpx-sdk/helpers/auth';
import firebase from '@mpx-sdk/helpers/firebase';
import { Button, Stack, Typography } from '@mui/material';
import * as Sentry from '@sentry/nextjs';
import NextError from 'next/error';
import Link from 'next/link';
import { usePathname } from 'next/navigation';
import { type ReactElement, memo, useEffect } from 'react';

interface GlobalErrorProps {
	/** The error that occurred. */
	error: Error;
}

/** Renders an error page. */
function GlobalError({ error }: GlobalErrorProps): ReactElement {
	/** The current pathname */
	const pathname = usePathname();

	async function logout() {
		await firebase.logout();
		Auth0Helper.logout();
	}

	useEffect(() => {
		Sentry.captureException(error);

		if (error) {
			// If the error is too many redirects, log the user out
			if (error.message.includes('redirect')) {
				logout();
			}
		}
	}, [error]);

	return (
		<html lang='en'>
			<body>
				<Stack
					alignItems='center'
					direction='column'
					justifyContent='center'
					spacing={2}
					sx={{
						background: 'none',
						backgroundImage: 'none',
						flexGrow: 1,
						minHeight: '60vh',
						position: 'relative',
						width: '100vw',
						zIndex: 100,
					}}
				>
					<Typography
						component='h1'
						sx={{
							color: 'error.main',
							fontSize: 'clamp(1.5rem, 2.5rem, 2.5rem)',
							fontWeight: 'bold',
							textAlign: 'center',
						}}
					>
						Oops! Something went wrong.
					</Typography>

					<Typography
						component='h2'
						sx={{
							fontSize: 'clamp(1rem, 1.5rem, 1.5rem)',
							fontWeight: 'bold',
							textAlign: 'center',
						}}
					>
						<NextError statusCode={undefined as any} />
					</Typography>

					<Link
						aria-label='Go home'
						href='/'
						onClick={() => {
							if (pathname === '/' && typeof window !== 'undefined') {
								// Reload the page
								window.location.reload();
							}
						}}
						style={{
							cursor: 'pointer',
						}}
					>
						<Button
							aria-label='Go home button'
							sx={{
								backgroundColor: 'primary.main',
								borderRadius: '32px',
								fontSize: '0.5rem',
								lineHeight: '2rem',
								transition: 'all 1s ease',
								'&:hover': {
									backgroundColor: 'primary.light',
									transition: 'all 0.25s ease',
								},
							}}
							variant='contained'
						>
							<Typography
								sx={{
									color: 'text.contrast',
									textDecoration: 'none',
									textTransform: 'none',
								}}
							>
								Go Home
							</Typography>
						</Button>
					</Link>
				</Stack>
			</body>
		</html>
	);
}

export default memo(GlobalError);
